import React from "react";
import { render, renderToString } from "./js-lib/utils";
import constants from "./constants";

const obj = document.querySelector("#impress");

const elements = constants.steps.map((s, n) => {
  return (
    <div
      key={n}
      id={s.id || undefined}
      className={`step ${s.className}`}
      data-x={s.x}
      data-y={s.y}
      data-z={s.z}
      data-rotate={s.rotate}
      data-rotate-x={s.rotateX}
      data-rotate-y={s.rotateY}
      data-rotate-z={s.roateZ}
      data-scale={s.scale}
    >
      {s.content.map((c, i) => React.cloneElement(c, { key: i }))}
    </div>
  );
});

render(elements, obj);

const impressObj = impress();
impressObj.init();
