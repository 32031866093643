import React from "react";

import { pp150px, pp150pxfull} from './js-lib/assets'
console.log(pp150px, pp150pxfull)
const data = {
  steps: [
    {
      className: "welcome",
      rotate: -90,
      scale: 1,
      id: "welcome",
      content: [<h1>สวัสดีครับ</h1>, <h3>Hello World!</h3>],
    },
    {
      className: "my-name",
      scale: 3,
      id: "myname",
      x: 4500,
      y: 1500,
      content: [
        <div>
          <img src={pp150pxfull} width="460" height="542"/>
        </div>,
        <h3>ประพันธ์ โอฬารพันธ์เลิศ</h3>,
      ],
    },
    {
      content: [
        <div></div>
      ]
    },
  ],
};

module.exports = data;
